<template>
  <div class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
       @mouseenter="$sidebar.onMouseEnter()"
       @mouseleave="$sidebar.onMouseLeave()">
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand">
          <el-select
            v-if="accounts.length > 1 || (accounts[0] && accounts[0].restaurants.length > 1)"
            v-model="selected"
            :filter-method="filterAccounts"
            value-key="restaurantId"
            placeholder="Choose location"
            class="select-primary"
            popper-class="h-575"
            filterable
          >
            <el-option-group
              v-for="account in filteredAccounts"
              :key="account.id"
              :label="account.name ? (canManageAccounts ? (account.completed ? '✅ ' : '🚧 ') + account.name + ` (${account.subscription ? account.subscription.name : '⚠️ No subscription'}) [${account.id}]` : account.name) : 'null'">
              <el-option
                class="select-primary"
                v-for="restaurant in account.restaurants"
                :key="restaurant.id"
                :class="{'deleted': restaurant.deleted}"
                :label="restaurant.name"
                :value="{restaurantId: restaurant.id, accountId: account.id}">
                {{ restaurant.deleted ? `🗑 ${restaurant.name} (DELETED)` : (restaurant.closed ? `🔒 ${restaurant.name} (closed)` : restaurant.name) }}
                <span v-if="canManageAccounts" style="float: right; color: #d7d7d7">{{ restaurant.id }}</span>
              </el-option>
              <el-option
                v-if="account.restaurants.length < 1"
                class="select-primary"
                :key="account.id + 'option'"
                :label="'No restaurants found'"
                :value="{restaurantId: null, accountId: account.id}"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <img v-else :src="logo" class="navbar-brand-img" alt="Sidebar logo">

<!--          <select v-if="restaurants.length > 1" v-model="selected" class="form-control" id="exampleFormControlSelect1">-->
<!--            <option v-for="restaurant in restaurants" :key="restaurant.id" :value="restaurant.id">{{restaurant.name}}</option>-->
<!--          </select>-->
<!--          <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>-->
        </a>
<!--        <div class="ml-auto">-->
<!--          &lt;!&ndash; Sidenav toggler &ndash;&gt;-->
<!--          <div class="sidenav-toggler d-none d-xl-block"-->
<!--               :class="{'active': !$sidebar.isMinimized }"-->
<!--               @click="minimizeSidebar">-->
<!--            <div class="sidenav-toggler-inner">-->
<!--              <i class="sidenav-toggler-line"></i>-->
<!--              <i class="sidenav-toggler-line"></i>-->
<!--              <i class="sidenav-toggler-line"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
          <sidebar-item
            v-if="isAdmin"
            :link="hubspotLinkObj" :class="{'disabled' : !hubspotLinkObj.isUrl}">
          </sidebar-item>
          <sidebar-item
            v-if="isAdmin && canManageFinancial"
            :link="moneybirdLinkObj" :class="{'disabled' : !moneybirdLinkObj.isUrl}">
          </sidebar-item>
          <sidebar-item
            v-if="isAdmin && canManageFinancial"
            :link="payautLinkObj" :class="{'disabled' : !payautLinkObj.isUrl}">
          </sidebar-item>
          <sidebar-item
            v-if="isAdmin && stripeLinkObj"
            :link="stripeLinkObj" :class="{'disabled' : !stripeLinkObj.isUrl}">
          </sidebar-item>

        </ul>
        <slot name="links-after"></slot>
      </div>


      <div class="fixedBottom">
        <div class="ml-auto">
          <!-- Sidenav toggler -->

            <div class="sidenav-toggler d-none d-xl-block"
                 :class="{'active': !$sidebar.isMinimized }"
                 @click="minimizeSidebar">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
<!--              <el-tooltip v-if="!$sidebar.isMinimized" content="Unpin sidebar" placement="left" popper-class="mr-3">-->
<!--                <div class="position-absolute" style="top: 0; left: 0; width:100%; height:100%"></div>-->
<!--              </el-tooltip>-->

            </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>

import store from "@/store";

export default {
  name: 'sidebar',
  data() {
    return {
      filteredAccounts: [],
      // selected: null,
    }
  },
  computed: {
    accounts() {
      return this.$store.state.accounts.filter(acc => (acc.name !== null || acc.restaurants.length > 0) &&
        (this.canManageAccounts || (acc.subscription !== null && acc.subscription.database_name !== 'inactive')) )
    },
    canManageAccounts() {return this.$store.getters.canManageAccounts},
    canManageFinancial() {return this.$store.getters.canManageFinancial},
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    hubspotId() {
      return this.$store.getters.currentRestaurant?.hubspot_company_id;
    },
    hubspotLinkObj(){
      if(this.hubspotId){
        return {'path': `https://app.hubspot.com/contacts/8545281/company/${this.hubspotId}/`,'target': '_blank', 'isUrl': true, 'isRoute': false, 'name': 'Hubspot', icon:'fab fa-hubspot text-default'}
      }else{
        return {'path': '/settings', 'name': 'Hubspot (not linked)', icon:'fab fa-hubspot text-default'}
      }
    },
    stripeLinkObj(){
      let id = this.$store.getters.currentRestaurant?.stripe_subscription_id;
      if(id){
        return {'path': `https://dashboard.stripe.com/subscriptions/${id}/`,'target': '_blank', 'isUrl': true, 'isRoute': false, 'name': 'Stripe', icon:'fab fa-stripe text-default'}
      }else{
        return null //TODO remove when Stripe subscription is linked in Orderli
        return {'path': '/settings#account', 'name': 'Stripe (no subscriptions)', icon:'fab fa-stripe text-default'}
      }
    },
    moneybirdLinkObj(){
      let id = this.$store.getters.currentRestaurant?.moneybird_contact_id;
      if(id){
        return {'path': `https://moneybird.com/273305291622188976/contacts/${id}/`,'target': '_blank', 'isUrl': true, 'isRoute': false, 'name': 'Moneybird', icon:'fas fa-dove text-default'}
      }else{
        return {'path': '/settings#account', 'name': 'Moneybird (not linked)', icon:'fas fa-dove text-default'}
      }
    },
    payautLinkObj(){
      let id = this.$store.getters.currentRestaurant?.payaut_account_holder_id;
      if(id){
        return {'path': `https://dashboard.payaut.com/sellers-details?sellerCode=${id}&merchantCode=FD5CKXPn8wXNNRiEpD2YzJMhADktay9CpVN&divisionCode=FD5CKXQRBBvnPReUTMa31J3f7KsCtez4MyS`,'target': '_blank', 'isUrl': true, 'isRoute': false, 'name': 'Payaut', icon:'fas fa-money-check-dollar text-default'}
      }else{
        return {'path': '/settings#payments', 'name': 'Payaut (not setup)', icon:'fas fa-money-check-dollar text-default'}
      }
    },
    selected: {
      get() {
        return {restaurantId: this.$store.state.auth.restaurantId, accountId: this.$store.state.auth.accountId}
      },
      set (value) {
        this.$store.commit('setAccount', value.accountId)
        this.$store.commit('setRestaurantWithReload', value.restaurantId)
      }
    }
  },
  watch: {
    accounts: {
      handler(newAllAccounts) {
        this.filteredAccounts = [...newAllAccounts]
      },
      immediate: true
    }
  },
  props: {
    title: {
      type: String,
      default: 'Orderli',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/img/brand/green.png',
      description: 'Sidebar app logo'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    filterAccounts(params){
      let filterValue = params.toLowerCase();
      let searchResultsArr = []
      this.accounts.forEach(account => {
        if(account.name?.toLowerCase().includes(filterValue) || account.id == filterValue) {
          searchResultsArr.push(account) //Add the account to the search results array
        }
        else{
          // Account name didn't match the search, try searching the restaurants for name and database name.
          // Also try matching for an exact match on ID with the restaurant ID
          let filteredRestaurants = account.restaurants.filter(restaurant => restaurant.name.toLowerCase().includes(filterValue) || restaurant.database_name.toLowerCase().includes(filterValue) || restaurant.id == filterValue);
          if(filteredRestaurants.length > 0) searchResultsArr.push({...account, 'restaurants': filteredRestaurants}) //Use ES6 Object deconstructing to copy

        }
      })
      this.filteredAccounts = searchResultsArr;
    }
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
    this.minimizeSidebar()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>

<style>
  .h-575 .el-select-dropdown__wrap {
    max-height: 575px;
    margin-right: 0;
  }

  .navbar-vertical.navbar-light{
    overflow: hidden;
  }



  .fixedBottom{
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }

  .g-sidenav-show .fixedBottom{
    display: block;
  }

  .fixedBottom .sidenav-togger{
    padding: 1.4rem;
  }

  .deleted{
    opacity: 0.5;
  }

</style>
